














import { Vue, Component } from "vue-property-decorator";
import Breadcrumb from "@/components/assess/Breadcrumb.vue";
@Component({
    components: {
        breadcrumb: Breadcrumb,
    },
})
export default class Case extends Vue {}
